import React, { startTransition} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router-dom';

import quadchainLogo from '../Images/quadchainlogov2.svg';
import '../css/BannerLayout.css';
import footerlogo from '../Images/footer-logo.svg';

function Footer() {
    const navigate = useNavigate();
    const navigatetoservices = (href) => {
        // setFadeOut(true);
        // setTimeout(() => {
        //     navigate('/Body');
        // }, 500);
        startTransition(() => {
          navigate(href);
        });
      };
    return (
        <div className='footer'>
            <Container>
                <Row className="gap-footer"> 
                    <Col className='Footercontact'>
                        <img src={quadchainLogo} />
                        <span className='footer-submsg' style={{lineHeight:"30px"}}>
                            © Copyright 2024</span>
                        <span className='footer-submsg' style={{lineHeight:"30px"}}> All Rights Reserved</span>
                        <div className='oval-shade'></div>
                    </Col>
                    {/* <Col></Col> */}
                    <Col className='flex-column' style={{position:"relative"}}>
                    <div>
                    <img src={footerlogo} className="footerllogo" />
                    </div>
                        <Stack direction="horizontal" gap={3}  style={{display:"inline-flex", marginBottom:"2rem"}}>
                            <div className="p-2">
                            
                                {/* <div className="p-2 footer-msg-logo">QUADCHAIN</div> */}
                                <Stack gap={0} style={{ textAlign: "left"}}>
                                    <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/services")}} >Services</div>
                                    <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/diagnosis-ai")}}>Solutions</div>
                                    <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/industries")}}>Industries</div>
                                </Stack></div>
                            <div className="p-2"><Stack gap={0} style={{ textAlign: "left", zIndex:"10000" }}>
                                {/* <div className="p-4 footer-msg-logo" style={{ color:"transparent" }}>QUADCHAIN</div> */}
                                
                                <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/about-us")}}>About Us</div>
                                <div className="p-2 footer-submsg" onClick={() =>{navigatetoservices("/contact-us")}}>Contact Us</div>
                                <div className="p-2 footer-submsg" style={{color:"transparent"}}>Resources</div>
                            </Stack></div>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer